import axios from 'axios';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    // allSchools is used as state instead of as getter, because the dropdowns in SchuelerUserAssignBySchool only seem to work with a schools state
    allSchools: [],
    allSchueler: [],
    showActiveCases: true,
    defaultSortPreferences: {
        sortOrder: 'asc',
        propertyToSortBy1: 'schueler_name',
        propertyToSortBy2: 'schueler_vorname'
    },
    sortPreferences: {
        Home: {},
        SchuelerUserAssignIndividually: {},
        SchuelerUserAssignBySchool: { sortorder: 'asc', propertyToSortBy1: 'school_name', propertyToSortBy2: '' },
        SchuelerUsersAssignGY: {},
    }
  },
  getters: {
    schoolsFilteredByBetreuungsfallStatus: (state) => {
        var schoolsFilteredByBetreuungsfallStatus = state.allSchools;
        schoolsFilteredByBetreuungsfallStatus = schoolsFilteredByBetreuungsfallStatus.filter(one_school => {
            var schoolsWithSelectedBetreuungsfallStatusExist = false;
            for (var i = 0; i < one_school.schueler.length; i++) {
              if (one_school.schueler[i].betreuungsfall_abgeschlossen !== Number(state.showActiveCases)){
                schoolsWithSelectedBetreuungsfallStatusExist = true;
                break;
              }
            }
            return schoolsWithSelectedBetreuungsfallStatusExist;
        });
        return schoolsFilteredByBetreuungsfallStatus;
    },
    schuelerFilteredByBetreuungsfallStatus: (state) => {
        return state.allSchueler.filter(schueler =>
            state.showActiveCases ? schueler.betreuungsfall_abgeschlossen === 0 : schueler.betreuungsfall_abgeschlossen === 1
        );
    },
    stateSorted: (state, getters) => (component, dataToSort) => {
        const defaultPreferences = state.defaultSortPreferences;
        const userPreferences = state.sortPreferences[component] || {};
        // Use the default sort preferences. If there are user preferences overwrite the default sort preferences
        const sortPreferences = { ...defaultPreferences, ...userPreferences };

        const { sortOrder, propertyToSortBy1, propertyToSortBy2, isUser } = sortPreferences;

        let stateSorted;
        if (dataToSort === 'schueler') {
          // Create a copy with slice to make Vue detect the changes to the students, when the students are sorted differently
          stateSorted = getters.schuelerFilteredByBetreuungsfallStatus.slice();
        } else if (dataToSort === 'schools') {
          stateSorted = getters.schoolsFilteredByBetreuungsfallStatus.slice();
        } else {
          return [];
        }

        function propComparator(order, prop1, prop2, isUser) {
            return function(a, b) {
                var a_lowerCase = "";
                var b_lowerCase = "";
                if (typeof isUser !== 'undefined') {
                    a_lowerCase = a['user_sfz'][prop1] ? a['user_sfz'][prop1].toLowerCase() : "";
                    b_lowerCase = b['user_sfz'][prop1] ? b['user_sfz'][prop1].toLowerCase() : "";
                }
                else if (typeof prop2 !== 'undefined' && prop2.length !== 0) {
                    a_lowerCase = a[prop1].toLowerCase() + a[prop2].toLowerCase();
                    b_lowerCase = b[prop1].toLowerCase() + b[prop2].toLowerCase();
                }
                else {
                    a_lowerCase = a[prop1] ? a[prop1].toLowerCase() : "";
                    b_lowerCase = b[prop1] ? b[prop1].toLowerCase() : "";
                }

                if (order === "desc") {
                    return b_lowerCase.localeCompare(a_lowerCase, 'de', {sensitivity: 'base'})
                }
                else {
                    return a_lowerCase.localeCompare(b_lowerCase, 'de', {sensitivity: 'base'})
                }
            }
        }

        stateSorted.sort(propComparator(sortOrder, propertyToSortBy1, propertyToSortBy2, isUser));
        return stateSorted;
    }
  },
  mutations: {
    setAllSchools(state, allSchools) {
      state.allSchools = allSchools;
    },
    setAllSchueler(state, allSchueler) {
        // allSchueler.user_sfz.name is accessed in SchuelerUserAssignIndividually, which leads to an error,
        // if no user is associated with the schueler. Therefore, in this case user_sfz.name null is added to schueler.user_sfz (FLOG - 10.08.2023)
        if (this.state.auth.schoolType === "SFZ" && this.state.auth.user && this.state.auth.user.is_admin === 1)
        {
            allSchueler.forEach(function (e) {
                if (e.user_sfz === null){
                    e.user_sfz = { name: null }
                }
            })
        }
        state.allSchueler = allSchueler;
    },
    setSortPreferences(state, { component, preferences }) {
        state.sortPreferences[component] = preferences;
    },
    deleteSchuelerFromState(state, payload){
        let schueler_state_in_use;
        if (this.state.auth.user && payload.canSeeUsers)
        {
            if (state.showActiveCases === true)
            {
                schueler_state_in_use = "allActiveSchuelerCases";
            }
            else {
                schueler_state_in_use = "allClosedSchuelerCases";
            }
        }
        else {
            schueler_state_in_use = "allSchueler";
        }

        let index = state[schueler_state_in_use].findIndex(schueler => schueler.id === payload.id)
        if (index !== -1){
            state[schueler_state_in_use].splice(index, 1);
        }
    },
    setShowActiveCases(state, showActiveCases) {
      state.showActiveCases = showActiveCases;
    }
  },
  actions: {
    getAllSchueler({commit, dispatch, rootGetters, rootState}){
      commit('setLoading', true, {root: true});
      commit('config/addSchoolToServerUrl', rootState.auth.school, {root: true});
      axios.get(rootGetters.getUrl('/api/all-schueler'))
        .then((res) => {
          res.data.forEach(function (e) {
            if (!e.schule){
              e.schule = "Keiner Schule zugeordnet";
            }
          });
          commit('setAllSchueler', res.data);
          if (rootState.auth.schoolType === "SFZ") {
            dispatch('setAllSchools');
          }
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('setAllSchueler', null);
          commit('setLoading', false, {root: true});
        });
    },
    editAssignment({commit, rootGetters}, payload) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.patch(rootGetters.getUrl('/admin/schueler-user-assign-sfz/' + payload.individuallyOrBySchool), payload.schuelerData)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            router.push('/administration/schueler-user-assign');
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setAllSchueler({commit}, payload) {
        commit('setAllSchueler', payload.allSchueler);
    },
    setShowActiveCases({commit}, payload) {
        commit('setShowActiveCases', payload.showActiveCases);
    },
    setSortPreferences({ commit }, { component, preferences } ) {
        commit('setSortPreferences', { component, preferences });
    },
    setAllSchools({ commit, state }) {
        const schuelerFilteredByBetreuungsfallStatus = state.allSchueler;
        let allSchoolsArray = [];
        let schoolNameArray = [...new Set(schuelerFilteredByBetreuungsfallStatus.map(item => item.schule))];

        // Filter out all students of each school
        schoolNameArray.forEach(function (e) {
          let school = {};
          let schuelerOfOneSchoolArray = schuelerFilteredByBetreuungsfallStatus.filter(obj => {
            return obj.schule === e
          })

          // If a user is assigned to all students of a school, this user is written into school.user_sfz. Otherwise school.user_sfz = null.
          var current_user;
          for (var i = 0; i < schuelerOfOneSchoolArray.length; i++) {
            if (schuelerOfOneSchoolArray[i].user_sfz){
                school.user_sfz = schuelerOfOneSchoolArray[i].user_sfz.name;
                if (typeof current_user !== 'undefined' && current_user !== schuelerOfOneSchoolArray[i].user_sfz.name) {
                  school.user_sfz = null;
                  break;
                }
                current_user = schuelerOfOneSchoolArray[i].user_sfz.name;
            }
          }

          school.schueler = schuelerOfOneSchoolArray;
          school.school_name = e;
          allSchoolsArray.push(school);
        })

        commit('setAllSchools', allSchoolsArray); }
  },
  modules: {}
}
